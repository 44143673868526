<template>
  <div>
    <base-header type="gradient-warning" class="pb-6 pb-8 pt-5 pt-md-8" />
    <b-container class="mt--7" fluid>
      <b-row class="justify-content-center">
        <b-col md="6">
          <card>
            <VeeObserver ref="form" slim>
              <b-form-group label="">
                <VeeProvider name="customer" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Owner Name"
                    label="Owner Name"
                    :disabled="true"
                    v-model="payload.customer.name"
                    :error="errors[0]"
                  />
                </VeeProvider>
                <VeeProvider name="owner" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Owner Email"
                    label="Owner Email"
                    v-model="payload.owner.email"
                    :disabled="true"
                    :error="errors[0]"
                  />
                </VeeProvider>
                <VeeProvider name="name" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Name"
                    label="Company Name"
                    v-model="payload.name"
                    :disabled="true"
                    :error="errors[0]"
                  />
                </VeeProvider>
                <VeeProvider name="document" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Document"
                    label="Document"
                    :disabled="true"
                    v-model="payload.document"
                    :error="errors[0]"
                  />
                </VeeProvider>
                <VeeProvider name="vat" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="VAT"
                    label="VAT"
                    :disabled="true"
                    v-model="payload.vatNumber"
                    :error="errors[0]"
                  />
                </VeeProvider>
              </b-form-group>
              <b-form-group label="Company Contacts">
                <VeeProvider name="email" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Email"
                    label="Email"
                    :disabled="true"
                    v-model="payload.email"
                    :error="errors[0]"
                  />
                </VeeProvider>
                <VeeProvider name="mobile" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Phone Number"
                    label="Mobile Number"
                    :disabled="true"
                    v-model="payload.mobileNumber"
                    :error="errors[0]"
                  />
                </VeeProvider>
                <VeeProvider name="phone" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Phone Number"
                    label="Phone Number"
                    :disabled="true"
                    v-model="payload.phoneNumber"
                    :error="errors[0]"
                  />
                </VeeProvider>
              </b-form-group>
              <b-form-group label="Address Company">
                <VeeProvider name="postcode" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Postcode"
                    label="Postcode"
                    :disabled="true"
                    v-model="payload.address.postcode"
                    :error="errors[0]"
                  />
                </VeeProvider>
                <VeeProvider name="addressLine1" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Addres Line 1"
                    label="Addres Line 1"
                    :disabled="true"
                    v-model="payload.address.address_line_1"
                    :error="errors[0]"
                  />
                </VeeProvider>
                <VeeProvider name="addressLine2" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Addres Line 2"
                    label="Addres Line 2"
                    :disabled="true"
                    v-model="payload.address.address_line_2"
                    :error="errors[0]"
                  />
                </VeeProvider>
                <VeeProvider name="addressLine3" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Addres Line 3"
                    label="Addres Line 3"
                    :disabled="true"
                    v-model="payload.address.address_line_3"
                    :error="errors[0]"
                  />
                </VeeProvider>
                <VeeProvider name="town" rules="required" v-slot="{ errors }">
                  <base-input
                    class="input-group-alternative"
                    placeholder="Town|City"
                    label="Phone Number"
                    :disabled="true"
                    v-model="payload.address.town"
                    :error="errors[0]"
                  />
                </VeeProvider>
              </b-form-group>
              <b-form-group label="List Documents attached">
                <li v-for="document in payload.documents" class="nav-item" :key="document.id">
                  <a :href="resource(document.path)" target="_blank">{{ document.name }}</a>
                </li>
              </b-form-group>
              <b-button-group>
                <base-button
                  v-if="!['E', 'W', 'D'].includes(payload.status)"
                  variant="primary"
                  type="submit"
                  class="my-4"
                  @click="approval()"
                  :loading="loading"
                >
                  <i class="fa fa-save"></i> Aprroval
                </base-button>
                <base-button
                  v-if="!['E', 'W', 'R', 'D'].includes(payload.status)"
                  variant="danger"
                  type="submit"
                  class="my-4"
                  @click="deny()"
                  :loading="loading"
                >
                  <i class="fa fa-ban"></i> Deny
                </base-button>
                <base-button
                  v-if="!['E', 'W', 'D'].includes(payload.status)"
                  variant="warning"
                  type="submit"
                  class="my-4"
                  @click="fixing()"
                  :loading="loading"
                >
                  <i class="fa fa-edit"></i> Fixing
                </base-button>
              </b-button-group>
            </VeeObserver>
          </card>
        </b-col>
      </b-row>
    </b-container>
    <modal :show="showModal">
      <template v-slot:header>
        <h3 class="modal-title" id="modal-title-default">{{ titleModal }}</h3>
      </template>
      <b-form-group label="Message(Described)">
        <ckeditor v-model="message" name="about"></ckeditor>
      </b-form-group>
      <template v-slot:footer>
        <base-button type="primary" class="my-4" @click="answer()">
          <i class="fa fa-save"></i> Save changes</base-button
        >
        <base-button type="link" class="my-4" @click="showModal = false">Close </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import CompaniesService from "features/companies/services/CompaniesService";

import { resource } from "helpers";

export default {
  name: "FormCompanies",
  data: () => ({
    loading: false,
    message: "",
    status: "",
    showModal: false,
    titleModal: "Review Company",
    payload: {
      name: "",
      document: "",
      vat: "",
      email: "",
      mobileNumber: "",
      phoneNumber: "",
      vatNumber: "",
      customer: [],
      address: [],
      image: [],
      owner: [],
      status: "",
      documents: [],
    },
    resource,
  }),
  computed: {
    isEdit() {
      return this.$route.name === "companies-edit" || "companies-review";
    },
  },
  mounted() {
    if (this.isEdit) this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await CompaniesService.show(this.$route.params.id);
        this.payload.name = data.name;
        this.payload.document = data.document;
        this.payload.email = data.email;
        this.payload.status = data.status;
        this.payload.phoneNumber = data.phone_number;
        this.payload.mobileNumber = data.mobile_number;
        this.payload.vatNumber = data.vat_number;
        this.payload.address = data.address;
        this.payload.customer = data.customer;
        this.payload.owner = data.owner;
        this.payload.documents = data.documents;
        this.payload.image = [resource(data.image)];
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Failed to get registration data",
        });
      } finally {
        this.loading = false;
      }
    },
    async fixing() {
      try {
        this.showModal = true;
        this.titleModal = "Review Company";
        this.status = "I";
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Update failed",
          text: "Verify the given informations and try again",
        });
      } finally {
        this.loading = false;
      }
    },
    async deny() {
      try {
        this.showModal = true;
        this.titleModal = "Deny Company";
        this.status = "R";
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Update failed",
          text: "Verify the given informations and try again",
        });
      } finally {
        this.loading = false;
      }
    },
    async approval() {
      try {
        this.message = "";
        this.showModal = true;
        this.titleModal = "Aprroval Company";
        this.status = "W";
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Update failed",
          text: "Verify the given informations and try again",
        });
      } finally {
        this.loading = false;
      }
    },
    async inReview() {
      try {
        this.message = "";
        this.showModal = true;
        this.titleModal = "In Review Company";
        this.status = "I";
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Update failed",
          text: "Verify the given informations and try again",
        });
      } finally {
        this.loading = false;
      }
    },
    async answer() {
      try {
        if (this.message) {
          await CompaniesService.answer(this.status, this.message, this.$route.params.id);
          this.$swal({
            icon: "success",
            title: "Updated successfully",
          }).then(() => this.$router.push("/companies"));
          this.showModal = false;
        } else {
          this.$swal({
            icon: "error",
            title: "Message is required!",
            text: "Verify the field  message.",
          });
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Update failed",
          text: "Verify the given informations and try again",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
